@import 'styles/common-mixins';

.card-container {
    border-top: 1px solid #eaeded;
    border-radius: 0;
    margin: 1rem auto 2rem;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15),
        -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
    word-wrap: break-word;
}

.card-container p {
    padding: 0 2rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;
}

.card-container__actions {
    position: relative;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
}

.card-container__toggle {
    width: 8%;
}

.card-container hr {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    height: 1px;
    border: 0px;
    background-color: #aab7b8;
}

.card-container__header {
    padding: 2rem;

    &.with-status-label {
        @include opposites();
    }
}

.card-container__header h3 {
    display: inline-block;
    margin-right: 1.5rem;
}

.card-container__header span {
    text-transform: uppercase;
    color: #545b64;
}

.card-container__icon {
    display: inline-block;
}
