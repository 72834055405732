@import 'styles/common-mixins';

awsui-table.access-codes-table {
    display: block;
}

awsui-table.access-codes-table h3 {
    font-weight: bold;
}

.access-codes-table .awsui-table-regions-container,
.access-code-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.landing-page-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.access-codes-table .awsui-table-regions-container > .awsui-table-has-pagination.awsui-table-tools {
    padding-top: 1.4rem;
}

.access-code-description {
    font-style: italic;
}

.access-code-url {
    @include truncated-link();
    max-width: 425px;
}

.landing-page-url {
    max-width: 425px;
}
