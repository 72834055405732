@import '~@amzn/awsui-components-react/sass/constants';

.jam-card {
    .card-container__header {
        border-top: 7px solid;
    }

    dd {
        margin-left: 0.1em;
    }
}
